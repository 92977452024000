import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { ButtonRecipe } from '../components/Button.css';
import ContentSection from '../components/ContentSection';
import FaqSection from '../components/FaqSection';
import Hero, { HeroProps } from '../components/Hero';
import Layout, { Section } from '../components/Layout';
import Link from '../components/Link';
import QuickInsights from '../components/QuickInsights';
import { Seo } from '../components/Seo';
import TestimonialSection from '../components/Testimonial';
import { Text, Title } from '../components/Text';
import ThoughtBubblesPerson from '../components/ThoughtBubblesSection';
import BusinessInequalityAmicoSvg from '../images/business-inequality-amico.svg';
import ContactUsAmicoSvg from '../images/contact-us-amico.svg';
import FourthVisualSvg from '../images/full-service/fourth-visual-open-closed-door.svg';
import HeroSvg from '../images/full-service/hero.svg';
import ThirdVisualSvg from '../images/full-service/third-visual-phone-call-guy.svg';
import MentionUsSvg from '../images/Mention-amico-1.svg';
import { sprinkles } from '../sprinkles.css';
import CustomerLogos from '../components/CustomerLogos';

const FullServicePage = ({
	data: { blogs, testimonialsData, allFaqJson },
}: {
	data: {
		locales: { edges: Queries.LocaleEdge[] };
		blogs: { edges: Queries.MdxEdge[] };
		testimonialsData: { nodes: Queries.TestimonialsJson[] };
		allFaqJson: { nodes: Queries.FaqJson[] };
	};
}) => {
	const { t, i18n } = useTranslation('FullServicePage');
	if (i18n.language === 'de') {
		const heroProps: HeroProps = {
			title: <Title level="title">Wir übernehmen Ihren Meldestellenbetrieb</Title>,
			text: 'Sie müssen Hinweisgeberschutz nach HinSchG umsetzen, wollen das Thema aber am liebsten outsourcen? Mit unserem Rundum-Sorglos-Paket können Sie sich von dem Thema verabschieden!',
			actions: [
				<Link
					key={1}
					to="https://my.meetergo.com/martinmeng/hinschg"
					className={ButtonRecipe({ size: 'large', variant: 'primary' })}
				>
					Jetzt beraten lassen
				</Link>,
			],
			image: (
				<img
					src={HeroSvg}
					alt="konfidal-Slogan 'schnell einfach konform' in einem überfüllten Großraumbüro."
					width="100%"
					height="auto"
					className={sprinkles({
						width: 'fill',
						minWidth: {
							mobile: 'three',
							tablet: 'fourhalf',
						},
					})}
				/>
			),
		};
		return (
			<Layout padding={false} limitWidth={false}>
				<Section direction="vertical" width="fill" gap="giant">
					<Hero width="wide" {...heroProps} />
					<CustomerLogos />
					<ThoughtBubblesPerson
						bubbleLeftText={t('ThoughtBubblesSection.BubbleLeft')}
						bubbleRightText={t('ThoughtBubblesSection.BubbleRight')}
					/>
					<ContentSection
						title={{
							__html:
								'Lehnen Sie sich zurück und konzentrieren Sie sich auf Ihr Kerngeschäft. Wir übernehmen ab hier - <strong>konfidal</strong>.',
						}}
						text="Unsere erfahrenen Compliance Experten übernehmen Ihre Whistleblower-Meldestelle und kommen nur dann auf Sie zu, wenn es absolut notwendig ist."
						cta1Text="Jetzt auslagern"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageSrc={BusinessInequalityAmicoSvg}
						imageAlt="Eine Person kann durch eine offene Tür mit der Aufschrift 'konfidal' gehen, während eine andere Person durch eine verschlossene Tür ohne Aufschrift blockiert wird."
					/>
					<ContentSection
						side="right"
						background="blue"
						title="Sie wollen mehr darüber erfahren, wie Sie als Unternehmen einfach, schnell und rechtssicher Hinweisgeberschutz auslagern können und dennoch über alles Wichtige auf dem Laufenden bleiben?"
						titleLevel="paragraph"
						text={{
							__html:
								'Die Spezialisten von <b>konfidal</b> <strong>beraten kostenlos</strong> zur Umsetzung der gesetzlichen Anforderungen und schaffen Optionenvielfalt, damit Sie Ihr passendes Hinweisgeberschutz-Konzept finden.',
						}}
						cta1Text="Jetzt Termin vereinbaren"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageSrc={ContactUsAmicoSvg}
						imageAlt="Ein Mitarbeiter des Kundensupports im Inneren eines Smartphones"
					/>
					<ContentSection
						side="right"
						title={{
							__html:
								'Mit dem <strong>konfidal</strong> Sorglospaket können Sie sich entspannt zurücklehnen und sich auf Ihr Kerngeschäft konzentrieren, während unsere Compliance-Spezialisten die eingehenden Berichte nach hochaktuellen Prüfschemata bearbeiten.',
						}}
						titleLevel="paragraph"
						text={{
							__html:
								'Ihre Schutzpflichten gegenüber Hinweisgebern und datenschutzrechtliche Aspekte werden von uns gewährleistet. Darüber hinaus sorgt unsere Expertise dafür, dass der gesetzlich vorgeschriebene Prozess abgeschlossen wird - ohne dass Sie sich um geschultes Personal oder die Einhaltung von Rückmeldefristen kümmern müssen.',
						}}
						cta1Text=""
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageSrc={ThirdVisualSvg}
						imageAlt="Ein Mann telefoniert und spricht dabei über Geld."
					/>
					<ContentSection
						side="left"
						title={{
							__html:
								'Vermeiden Sie Bußgelder von bis zu 50.000 EUR bei Nichtumetzung und entlasten Sie Ihre Organisation von zusätzlichem personellen und finanziellen Aufwand.',
						}}
						titleLevel="paragraph"
						text={{
							__html:
								'Entscheiden Sie sich jetzt für das Rundum-Sorglos-Paket zum HinSchG. Kontaktieren Sie uns für ein Angebot und lassen Sie sich von unseren Experten zur Übernahme Ihrer Pflichten beraten.',
						}}
						cta1Text="Tschüss HinSchG"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageSrc={FourthVisualSvg}
						imageAlt="todo"
					/>
					<TestimonialSection testimonials={testimonialsData.nodes} />
					<QuickInsights
						title="Fünf Vorteile auf einen Blick"
						insights={[
							{
								title: 'Risikominimierung',
								text: (
									<Text>
										Wenn Sie den Whistleblowing Full Service nutzen, können Sie
										potenzielle Compliance-Verstöße frühzeitig erkennen und sie proaktiv
										angehen.{' '}
										<b>
											Dies hilft, das Risiko von Strafen, Sanktionen und Rufschädigung
											erheblich zu verringern.
										</b>
									</Text>
								),
								icon: 'see-you-soon-amico-1.svg',
							},
							{
								title: 'Effiziente Ressourcennutzung',
								text: (
									<Text>
										Durch die Beauftragung externer Compliance-Spezialisten mit dem
										Betrieb der Zentrale für Meldungen, können Sie sicherstellen, dass Sie
										entweder kein Compliance-Fachwissen aufbauen müssen oder Ihre internen
										Compliance-Ressourcen effizient genutzt werden.{' '}
										<b>
											Sie können sich auf strategische Initiativen und andere wichtige
											Aufgaben konzentrieren.
										</b>
									</Text>
								),
								icon: 'law-firm-amico-1.svg',
							},
							{
								title: 'Unabhängigkeit und Objektivität',
								text: (
									<Text>
										Die Zusammenarbeit mit uns als unabhängigen Experten gewährleistet die
										Objektivität der Untersuchungen und vermeidet potenzielle
										Interessenkonflikte.{' '}
										<b>
											Dies trägt zur Glaubwürdigkeit Ihrer Compliance-Programme bei und
											stärkt das Vertrauen Ihrer Stakeholder.
										</b>
									</Text>
								),
								icon: 'collaboration-amico-1.svg',
							},
							{
								title: 'Hinweisgeberschutz',
								text: (
									<Text>
										<strong>konfidals</strong>{' '}
										<b>
											Software für den Betrieb von Meldekanälen gewährleistet, dass
											Whistleblower anonym bleiben können.
										</b>{' '}
										Das fördert eine offene Kommunikationskultur und erhöht die
										Wahrscheinlichkeit, dass Mitarbeiter Compliance-Verstöße melden, was
										Schäden eindämmt oder in einem frühen Stadium verhindert.
									</Text>
								),
								icon: 'messages-amico-1.svg',
							},
							{
								title: 'Permanente Verbesserung',
								text: (
									<Text>
										Der Whistleblowing Full Service hilft Ihnen bei der kontinuierlichen
										Verbesserung Ihrer internen Kontrollen, des Risikomanagements und der
										Compliance-Programme durch gemeinsames &quot;aus gemeldeten Vorfällen
										lernen&quot; <b>und bewährte Praktiken intern umsetzen</b>.
									</Text>
								),
								icon: 'processing-amico-1.svg',
							},
						]}
					/>
					<ContentSection
						background="blue"
						side="right"
						title="Mehr gute Gründe gefällig, warum Sie konfidal Ihren Meldestellenbetrieb übergeben sollten?"
						titleLevel="subsection"
						cta1Text="Jetzt kostenlos beraten lassen"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageSrc={MentionUsSvg}
						imageAlt="Person in einem Chat erscheint über dem Display eines Smartphones"
					/>
					<FaqSection
						content={allFaqJson.nodes[0].entries.filter((e) =>
							e.pages.includes('/full-service'),
						)}
						width="wide"
					/>
				</Section>
			</Layout>
		);
	}
	if (i18n.language === 'en') {
		return (
			<Layout padding={false} limitWidth={false}>
				<Section direction="vertical" width="fill" gap="giant">
					<Hero
						width="wide"
						{...{
							title: <Title level="title">Your Whistleblowing Success Partner</Title>,
							text: "Are you a decision-maker or specialist looking for solutions to implement the EU Whistleblower Directive, but don't know where to start?",
							actions: [
								<Link
									key={1}
									to="https://my.meetergo.com/martinmeng/hinschg"
									className={ButtonRecipe({ size: 'large', variant: 'primary' })}
								>
									Register for consultation
								</Link>,
							],
							image: (
								<img
									src={HeroSvg}
									alt="konfidal slogan 'fast simple compliant' surrounding a depictions of an ongoing meeting with one person persenting slides."
									width="100%"
									height="auto"
									className={sprinkles({
										width: 'fill',
										minWidth: {
											mobile: 'three',
											tablet: 'fourhalf',
										},
									})}
								/>
							),
						}}
					/>
					<CustomerLogos />
					<ContentSection
						title={{
							__html:
								"But now you can just sit back and relax, because we've created a complete solution for you - <strong>Konfidal</strong>.",
						}}
						text="Learn more during an introductory consultation with our experts."
						cta1Text="Make an appointment"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageSrc={BusinessInequalityAmicoSvg}
						imageAlt="One person can walk throught an open door labelled 'konfidal' while another person is blocked by a locked door without a label"
					/>
					<ContentSection
						side="right"
						background="blue"
						title="To ensure that you meet the legal requirements and make the right decision for your company, you should seek professional advice."
						titleLevel="paragraph"
						text={{
							__html:
								'Our <b>Konfidal</b> <strong>introductory consultation</strong> on Whistleblower Laws offers&nbsp;you exactly this support.',
						}}
						cta1Text="Contact Us"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageSrc={ContactUsAmicoSvg}
						imageAlt="A customer support employee inside a smartphone"
					/>
					<QuickInsights
						title="Five Advantages at a glance"
						insights={[
							{
								title: 'Risk reduction',
								text: (
									<Text>
										By using the Whistleblowing Full Service, you can identify potential
										compliance breaches at an early stage and address them proactively.
										<b>
											This helps to reduce the risk of fines, sanctions and reputational
											damage.
										</b>
									</Text>
								),
								icon: 'see-you-soon-amico-1.svg',
							},
							{
								title: 'Efficient use of resources',
								text: (
									<Text>
										By engaging external compliance specialists to run the whistleblowing
										centre, you can ensure that you either do not need to build up
										compliance expertise or that{' '}
										<b>
											your internal compliance resources are used efficiently and can
											focus on strategic initiatives and other important tasks
										</b>
										.
									</Text>
								),
								icon: 'law-firm-amico-1.svg',
							},
							{
								title: 'Independence and objectivity',
								text: (
									<Text>
										Working with us as independent experts ensures the objectivity of
										investigations and avoids potential conflicts of interest.{' '}
										<b>
											This contributes to the credibility of your compliance programmes
											and strengthens stakeholder trust.
										</b>
									</Text>
								),
								icon: 'collaboration-amico-1.svg',
							},
							{
								title: 'Protection of whistleblowers',
								text: (
									<Text>
										<strong>konfidal&apos;s</strong>{' '}
										<b>
											software for operating reporting channels ensures that
											whistleblowers can remain anonymous when raising concerns
										</b>
										. This promotes an open culture of communication and increases the
										likelihood that employees will report compliance breaches, which in
										turn allows you to stop them more quickly and avoid consequential
										damage at an early stage.
									</Text>
								),
								icon: 'messages-amico-1.svg',
							},
							{
								title: 'Continuous improvement',
								text: (
									<Text>
										The Whistleblowing Full Service helps you continuously improve your
										internal controls, risk management and compliance programmes by
										learning together from reported incidents{' '}
										<b>and implementing best practices in-house</b>.
									</Text>
								),
								icon: 'processing-amico-1.svg',
							},
						]}
					/>
					<ContentSection
						background="blue"
						side="right"
						title="Want more good reasons?"
						titleLevel="subsection"
						cta1Text="Make an appointment now"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageSrc={MentionUsSvg}
						imageAlt="TODO"
					/>
					<TestimonialSection testimonials={testimonialsData.nodes} />
					<FaqSection
						content={allFaqJson.nodes[0].entries.filter((e) =>
							e.pages.includes('/full-service'),
						)}
						width="wide"
					/>
				</Section>
			</Layout>
		);
	}
};
export default FullServicePage;

export const Head = () => {
	return <Seo title="kofidal Full Whistleblowing Service" />;
};

export const query = graphql`
	query ($language: String!) {
		sitePage {
			context {
				i18n {
					language
				}
			}
		}
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		blogs: allMdx(
			filter: {
				internal: { contentFilePath: { glob: "**/blog/**" } }
				frontmatter: { language: { eq: $language }, path: { ne: "how-to-implement" } }
			}
		) {
			edges {
				node {
					id
					frontmatter {
						title
						published_at
						path
						author
						featured_image {
							src {
								childImageSharp {
									gatsbyImageData(layout: FULL_WIDTH)
								}
							}
							alt
						}
					}
					excerpt(pruneLength: 250)
					internal {
						contentFilePath
					}
				}
			}
		}
		testimonialsData: allTestimonialsJson(
			filter: { language: { eq: $language }, slug: { in: ["tes-partner"] } }
		) {
			nodes {
				name
				company
				companyLogo {
					childImageSharp {
						gatsbyImageData(height: 50)
					}
				}
				personImage {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
				text
				personImageAlt
				companyLogoAlt
				link
			}
		}
		allFaqJson(filter: { language: { eq: $language } }) {
			nodes {
				entries {
					title
					content
					pages
				}
			}
		}
	}
`;
