import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { sprinkles } from '../sprinkles.css';
import * as theme from '../theme.css';
import { Section } from './Layout';
import { Title } from './Text';

const CustomerLogos = () => {
	const {
		i18n: { language },
	} = useTranslation();

	const images = useStaticQuery<{
		allContentfulCustomer: {
			nodes: {
				name: string;
				website?: string;
				logo: {
					gatsbyImageData: IGatsbyImageData;
				};
			}[];
		};
	}>(graphql`
		query {
			allContentfulCustomer {
				nodes {
					name
					website
					logo {
						gatsbyImageData(layout: CONSTRAINED, resizingBehavior: PAD, height: 96)
					}
				}
			}
		}
	`);

	const isTablet = useMediaQuery({ maxWidth: theme.width.desktopHalf });

	const logos1: any[] = [];
	const logos2: any[] = [];
	images.allContentfulCustomer.nodes
		.filter((n) => n?.logo?.gatsbyImageData != null)
		.forEach((n, i) => (i % 2 === 0 ? logos1 : logos2).push(n));
	// logos1.concat(...logos2.slice(0, 3))
	// logos2.concat(...logos1.slice(0, 3))

	return (
		/* TODO: hide scrollbar
			/* Hide scrollbar for Chrome, Safari and Opera
			.example::-webkit-scrollbar {
				display: none;
			}

			/* Hide scrollbar for IE, Edge and Firefox 
			.example {
				-ms-overflow-style: none;  /* IE and Edge 
				scrollbar-width: none;  /* Firefox 
			}
		*/ <Section align="center" direction="vertical" gap="larger" width="fill">
			<Title level="section" align="center">
				{language === 'de'
					? 'Diese Unternehmen vertrauen unseren Dienstleistungen'
					: 'Customers that trust our whistleblowing services'}
			</Title>
			<Swiper
				modules={[Autoplay]}
				slidesPerView="auto"
				loop={isTablet}
				spaceBetween={isTablet ? 32 : 64}
				autoplay={{
					delay: 2000,
					stopOnLastSlide: false,
					disableOnInteraction: false,
					waitForTransition: true,
					pauseOnMouseEnter: false,
				}}
				alignContent="center"
				className={sprinkles({ width: 'fill', height: 'one' })}
			>
				{logos1.map((n, i) => (
					// <GatsbyImage
					// 	key={e.node.name}
					// 	image={e.node.childImageSharp.gatsbyImageData}
					// 	alt={e.node.name}
					// />
					<SwiperSlide
						style={{
							display: 'flex',
							width: 'auto',
							justifyContent: 'center',
							alignItems: 'center',
							maxWidth: '300px',
						}}
						key={n.name + i}
					>
						<GatsbyImage key={n.name} image={n.logo?.gatsbyImageData} alt={n.name} />
					</SwiperSlide>
				))}
			</Swiper>
			<Swiper
				modules={[Autoplay]}
				slidesPerView="auto"
				loop={isTablet}
				spaceBetween={isTablet ? 32 : 64}
				autoplay={{
					delay: 2000,
					stopOnLastSlide: false,
					disableOnInteraction: false,
					waitForTransition: true,
					pauseOnMouseEnter: false,
				}}
				alignContent="center"
				className={sprinkles({ width: 'fill', height: 'one' })}
			>
				{logos2.map((n, i) => (
					// <GatsbyImage
					// 	key={e.node.name}
					// 	image={e.node.childImageSharp.gatsbyImageData}
					// 	alt={e.node.name}
					// />
					<SwiperSlide
						style={{
							display: 'flex',
							width: 'auto',
							justifyContent: 'center',
							alignItems: 'center',
							maxWidth: '300px',
						}}
						key={n.name + i}
					>
						<GatsbyImage key={n.name} image={n.logo?.gatsbyImageData} alt={n.name} />
					</SwiperSlide>
				))}
			</Swiper>
		</Section>
	);
};

export default CustomerLogos;
