import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import Link from '../components/Link';
import { sprinkles } from '../sprinkles.css';
import { ButtonRecipe } from './Button.css';
import { Section, SectionWidthOptions } from './Layout';
import { Text, Title } from './Text';
import { TitleLevels } from './Text.css';

export type ContentSectionProps = {
	side?: 'left' | 'right';
	title?: string | { __html: string };
	titleLevel?: TitleLevels;
	text?: string | { __html: string };
	background?: 'blue' | 'white';
	image?: React.ReactNode;
	imageSrc?: string | IGatsbyImageData;
	imageAlt?: string;
	cta1Text?: string;
	cta1Link?: string;
	cta2Text?: string;
	cta2Link?: string;
	textGrow?: '1' | '2' | '3';
	white?: boolean;
	width?: SectionWidthOptions;
	padding?: boolean;
};

const ContentSection = ({
	side = 'left',
	title,
	titleLevel = 'subsection',
	text,
	background,
	image,
	imageSrc,
	imageAlt,
	cta1Text,
	cta1Link,
	cta2Text,
	cta2Link,
	textGrow = '1',
	white,
	width = 'wide',
	padding = true,
}: ContentSectionProps) => {
	const imageNode = (image || imageSrc) && (
		<div
			className={sprinkles({
				flexBasis: 'three',
				flexGrow: '1',
				width: { mobile: 'two', tablet: 'two', desktopHalf: 'fill' },
				maxWidth: 'five',
				borderRadius: 'large',
			})}
		>
			{image ?? (imageSrc && (
			typeof imageSrc === 'string' ? (
				<img src={imageSrc} alt={imageAlt || ''} style={{ width:"100%", height:"auto"}} />
			) : (
				<GatsbyImage image={imageSrc} alt={imageAlt ?? 'Illustrative image'} />
			)))}
		</div>
	);
	const contentSection = (
		<Section
			width={width}
			direction="horizontal"
			gap="large"
			padding={padding}
			wrap
			align="center"
			justify="center"
			style={{
				flexWrap: side === 'right' ? 'wrap' : 'wrap-reverse',
			}}
		>
			{side === 'right' && imageNode}
			<Section
				direction="vertical"
				gap="large"
				className={sprinkles({
					flexBasis: 'three',
					flexGrow: textGrow,
					width: 'fill',
				})}
				align="start"
			>
				<Section direction="vertical" gap="medium" align="start">
					{title && (
						<Title white={white} level={titleLevel}>
							{typeof title === 'string' ? (
								title
							) : (
								<span dangerouslySetInnerHTML={title} />
							)}
						</Title>
					)}
					{text && (
						<Text white={white}>
							{typeof text === 'string' ? text : <span dangerouslySetInnerHTML={text} />}
						</Text>
					)}
				</Section>
				<Section direction="horizontal" gap="medium" justify="left">
					{cta1Text && cta1Link && (
						<Link
							to={cta1Link}
							className={ButtonRecipe({
								size: 'large',
								variant: 'primary',
								white,
							})}
						>
							{cta1Text}
						</Link>
					)}
					{cta2Text && cta2Link && (
						<Link
							to={cta2Link}
							className={ButtonRecipe({
								size: 'large',
								variant: 'secondaryText',
								white,
							})}
						>
							{cta2Text}
						</Link>
					)}
				</Section>
			</Section>
			{side === 'left' && imageNode}
		</Section>
	);

	if (background === 'blue') {
		return (
			<Section
				width="fill"
				direction="horizontal"
				justify="center"
				padding={false}
				className={sprinkles({
					backgroundColor: 'primaryBackground',
					paddingY: 'largerr',
				})}
			>
				{contentSection}
			</Section>
		);
	}

	return contentSection;
};
export default ContentSection;
