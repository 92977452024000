import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import WomanThinkingSvg from '../images/woman-thinking.svg';
import { sprinkles } from '../sprinkles.css';
import { Section } from './Layout';

const ThoughtBubblesPerson = ({
	bubbleLeftText,
	bubbleRightText,
}: {
	bubbleLeftText: string | React.ReactNode;
	bubbleRightText: string | React.ReactNode;
}) => {
	const isMobile = useMediaQuery({ maxWidth: 768 });
	const { thoughtBubbleLeft, thoughtBubbleRight, womanThinkingBackground } =
		useStaticQuery(graphql`
			query {
				thoughtBubbleLeft: file(
					sourceInstanceName: { eq: "images" }
					relativePath: { eq: "thought-bubble-left.svg" }
				) {
					publicURL
				}
				thoughtBubbleRight: file(
					sourceInstanceName: { eq: "images" }
					relativePath: { eq: "thought-bubble-left.svg" }
				) {
					publicURL
				}
				womanThinkingBackground: file(
					sourceInstanceName: { eq: "images" }
					relativePath: { eq: "woman-thinking-background.svg" }
				) {
					publicURL
				}
			}
		`);
	const bubbleLeft = (
		<div
			style={{
				backgroundImage: `url("${thoughtBubbleLeft.publicURL}")`,
				backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat',
				maxWidth: '350px',
				width: '300px',
				height: '200px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				// flexBasis: isMobile ? '200px' : '300px',
				marginBottom: isMobile ? -100 : 0,
				padding: isMobile ? '20px 40px 20px 20px' : '40px 80px 40px 40px',
				boxSizing: 'content-box',
			}}
		>
			<div style={{ width: '200px', textAlign: 'center' }}>{bubbleLeftText}</div>
		</div>
	);
	const bubbleRight = (
		<div
			style={{
				backgroundImage: `url("${thoughtBubbleRight.publicURL}")`,
				backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat',
				maxWidth: '350px',
				width: '300px',
				height: '200px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				// flexBasis: isMobile ? '200px' : '300px',
				alignSelf: 'flex-end',
				marginTop: isMobile ? -50 : 0,
				padding: isMobile ? '15px 30px 15px 15px' : '30px 60px 30px 30px',
				boxSizing: 'content-box',
			}}
		>
			<div style={{ width: '200px', textAlign: 'center' }}>{bubbleRightText}</div>
		</div>
	);
	return (
		<Section gap="largerrNeg" width="wide" align="stretch" padding>
			{isMobile && bubbleLeft}
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					backgroundImage: `url(${womanThinkingBackground.publicURL})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			>
				{!isMobile && bubbleLeft}
				<img
					className={sprinkles({
						paddingTop: {
							mobile: 'huge',
							tablet: 'huger',
							desktop: 'giant',
						},
						paddingBottom: {
							mobile: 'largerr',
							tablet: 'huge',
							desktop: 'huger',
						},
						maxWidth: 'onehalf',
					})}
					src={WomanThinkingSvg}
					alt="Thikning woman"
				/>
				{!isMobile && bubbleRight}
			</div>
			{isMobile && bubbleRight}
		</Section>
	);
};
export default ThoughtBubblesPerson;
